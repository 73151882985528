import React, { useEffect, useRef } from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

interface PriceInsertProps {
  price: number
  planCode: string
}

export const AffirmNewTest: React.FC<PriceInsertProps> = ({
  price,
  planCode,
}) => {
  const { classes, cx } = useStyles()
  // Define the useRef with an appropriate type that can hold either null or the prop types
  const prevValues = useRef<{ price: number | null; planCode: string | null }>({
    price: null,
    planCode: null,
  })

  useEffect(() => {
    // Execute refresh if it's the first run or if the values have changed
    if (
      prevValues.current.price !== price ||
      prevValues.current.planCode !== planCode
    ) {
      window.affirm.ui.ready(() => {
        window.affirm.ui.refresh()
      })

      // Update the previous values to current ones
      prevValues.current = { price, planCode }
    }
  }, [price, planCode])

  return (
    <Typography
      className={cx(classes.originalPrice, 'affirm-as-low-as')}
      data-page-type="product"
      data-amount={price * 100}
      data-learnmore-show="false"
      data-sku={planCode}
    />
  )
}

const useStyles = makeStyles()({
  originalPrice: {
    fontSize: '1rem!important',
    margin: '0',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
})

export default React.memo(AffirmNewTest)
