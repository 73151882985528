import { useEffect, useState } from 'react'

export const useScrollToViewPackages = (rowType: string) => {
  const [isDelayed, setIsDelayed] = useState(false)

  useEffect(() => {
    const hash = window.location.hash
    if (hash === '#view_packages') {
      if (rowType !== 'ContentfulProductPackagesSectionNewVersion4') {
        const timer = setTimeout(() => {
          setIsDelayed(true)
          const element = document.getElementById('view_packages')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }, 1000) // Delay other components by 1 second

        return () => clearTimeout(timer)
      } else {
        setIsDelayed(true) // No delay for ContentfulProductPackagesSectionNewVersion4
      }
    } else {
      setIsDelayed(true) // No delay if hash is not #view_packages
    }
  }, [rowType])

  return { isDelayed }
}
