import { PageProps, graphql } from 'gatsby'
import React from 'react'
import { ThemeProvider } from '@mui/material'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import BaseLandingPageV2Template from '@talentinc/gatsby-theme-ecom/components/Templates/LandingPageV2'
import useDiscountToken from '@talentinc/gatsby-theme-ecom/hooks/useDiscountToken'
import { LandingPageV2 } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'
import { ZipJobLandingTheme } from '../styles'
import useZJEcommEvents from '../hooks/useZJEcommEvents'
import { useLogFrontEndVisit } from '@talentinc/gatsby-theme-ecom/hooks/useLogFrontEndVisit'

const LandingPageV2Template: React.FC<
  PageProps<{ landingPageV2: LandingPageV2 } & PageDataContextType>
> = (props) => {
  const updatedData = props.data
  const discountToken = useDiscountToken()
  useZJEcommEvents()
  useLogFrontEndVisit()

  if (!updatedData.discount) {
    updatedData.discount = discountToken
  }
  const { landingPageV2, ...pageData } = updatedData

  return (
    <ThemeProvider theme={ZipJobLandingTheme}>
      <Layout pageTitle={landingPageV2.title} pageData={pageData}>
        <SEO landingPage={landingPageV2} />
        <HeaderV2 />
        <BaseLandingPageV2Template landingPage={landingPageV2} />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query LandingPagesV2BySlugAndBrand($slug: String!, $brandName: String!) {
    landingPageV2: contentfulLandingPageV2(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...LandingPageV2
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    authorPosts: allContentfulPost(
      filter: {
        brand: { elemMatch: { name: { eq: $brandName } } }
        node_locale: { eq: "en-US" }
        authors: { elemMatch: { authorName: { url: { eq: $slug } } } }
      }
    ) {
      nodes {
        contentful_id
        updatedAt
        slug
        title
        authors {
          name
          authorName {
            url
          }
          picture {
            url
          }
        }
        featuredImage {
          url
        }
        categories {
          name
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
  }
`

export default LandingPageV2Template
