import React, { useEffect, useMemo, useState } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { RichText as RichTextType } from 'types/post'
import backgroundAlpha from '@talentinc/gatsby-theme-ecom/images/hero_alpha.png'
import RichText from '../RichText'
import { RBBVariants } from '../../types/landingPageV2'
import { AssetFluid } from 'types/asset'
import loadable from '@loadable/component'
import { ToggleBtn } from '../ContentfulEntries/TextColumnGroupInsert'
import { IGenericGridReference } from 'types/grid'
import formatContentfulClasses from '../../utils/customClasses'

interface Props {
  variant: RBBVariants
  content: RichTextType
  bottom: RichTextType
  images: AssetFluid[]
  sectionClass: string
  codeClasses: string[] | null
}

const Image = loadable(() => import('../ContentfulEntries/Image'))

function RoundBackgroundBlock(props: Props) {
  const { content, variant, images, sectionClass, bottom, codeClasses } = props
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isExpanded, setIsExpanded] = useState(!isMobile)

  useEffect(() => {
    setIsExpanded(!isMobile)
  }, [isMobile])

  const isImageBg = [
    RBBVariants.HearFromCustomers,
    RBBVariants.HeroCentered,
  ].includes(variant)
  let imgCoords: Record<string, string>[]

  const isResumeExamples = variant === RBBVariants.ResumeExamples

  const Content = useMemo(() => {
    switch (variant) {
      case RBBVariants.ResumeExamples:
        imgCoords = [{ bottom: '0', right: '0' }]
        const grid = (content.references || [])[0] as IGenericGridReference
        const linksCount =
          (Array.isArray(grid?.gridCells) && grid.gridCells.length) || 0

        return (
          <Box className={classes.twoColumn}>
            <Box
              className={cx({
                [classes.leftColumn]: true,
                [classes.hidden]: isMobile && !isExpanded,
              })}
            >
              <RichText rawBody={content.raw} references={content.references} />
              {isMobile && (
                <ToggleBtn
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                  count={linksCount}
                />
              )}
            </Box>
            <Box className={classes.rightColumn}>
              {(images || []).map((image, index) => (
                <div
                  key={image.contentful_id}
                  style={imgCoords[index]}
                  className={cx(classes.img)}
                >
                  <Image asset={image} />
                </div>
              ))}
            </Box>
          </Box>
        )
      case RBBVariants.HearFromCustomers:
        imgCoords = [
          { top: '30%', left: '6rem' },
          { top: '65%', left: '12rem' },
          { top: '20%', right: '6rem' },
        ]
      default:
        return (
          <>
            {(images || []).map((image, index) => (
              <div
                key={image.contentful_id}
                style={imgCoords[index]}
                className={cx(classes.img)}
              >
                <Image asset={image} />
              </div>
            ))}
            <Box className={cx({
              [classes.centerColumn]: true,
              [classes.centerColumnPadding]: variant === RBBVariants.HearFromCustomers
            })}>
              <RichText rawBody={content.raw} references={content.references} />
            </Box>
          </>
        )
    }
  }, [variant, images, isExpanded])

  const Bottom = useMemo(() => {
    if (bottom) {
      return <RichText rawBody={bottom.raw} references={bottom.references} />
    }
    return null
  }, [bottom])

  return (
    <Box className={cx(...formatContentfulClasses(codeClasses || []))}>
      <Box
        className={cx({
          [sectionClass]: true,
          [classes.paddingVertical]: true,
          [classes.paddingTopOnly]: variant === RBBVariants.HeroCentered
        })}
      >
        <Box
          className={cx({
            [classes.innerContainer]: true,
            [classes.innerContainerEqPadding]: isResumeExamples,
            [classes.innerContainerGreyBg]: isResumeExamples,
            [classes.innerContainerHero]: variant === RBBVariants.HeroCentered,
          })}
        >
          {isImageBg && (
            <>
              <Box className={classes.bgImgLeft} />
              <Box className={classes.bgImgRight} />
            </>
          )}
          {Content}
        </Box>
        <Box
          className={cx({
            [classes.bottom]: true,
            [classes.bottomHearFromCustomers]:
              variant === RBBVariants.HearFromCustomers,
          })}
        >
          {Bottom}
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  bgImgLeft: {
    position: 'absolute',
    left: -150,
    bottom: 60,
    transform: 'rotate(-6deg)',
    width: 890,
    height: 961,
    background: `center center / 100% no-repeat url(${backgroundAlpha}) ${theme.colors.blue[10]}`,
    zIndex: 0,
  },
  bgImgRight: {
    position: 'absolute',
    right: -400,
    bottom: -200,
    transform: 'rotate(26deg)',
    width: 728,
    height: 786,
    background: `center center / 100% no-repeat url(${backgroundAlpha}) ${theme.colors.blue[10]}`,
    zIndex: 0,
  },
  bottom: {
    marginTop: 32,
    '& p': {
      color: theme.colors.neutral[50],
    },
    '& h1': {
      fontWeight: 400,
      color: theme.colors.neutral[90],
    },
  },
  bottomHearFromCustomers: {
    '& > p:last-child': {
      fontSize: 14,
      textAlign: 'center',
      marginTop: 32,
    },
  },
  img: {
    position: 'absolute',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: 'none',
    },
  },
  twoColumn: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
    flex: 1,
  },
  hidden: {
    '& > div > div:nth-child(n+7)': {
      display: 'none',
    },
  },
  leftColumn: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    padding: '80px',
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      paddingRight: '40px',
    },
    '& > div > div': {
      padding: '4px 8px !important',
    },
    '& a > div': {
      background: 'transparent !important',
      padding: '0 !important',
      color: theme.colors.blue[400],
      border: 'none !important',
      '& > img': {
        display: 'none',
      },
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '& > div': {
      padding: 0,
      marginTop: 15,
    },
    '& > div:last-child': {
      padding: 15,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '40px',
      textAlign: 'center',
    },
  },
  rightColumn: {
    flex: 3,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      display: 'none',
    },
  },
  centerColumn: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& > h2': {
      fontSize: '43px !important',
      fontWeight: '400 !important',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: 0,
    },
  },
  centerColumnPadding: {
    padding: '0 15rem',
  },
  paddingVertical: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
  },
  paddingTopOnly: {
    paddingTop: '4rem',
    paddingBottom: '0 !important',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingTop: '6%',
    },
  },
  heroContainer: {
    paddingBottom: '48px',
    paddingTop: '48px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: '40px',
      paddingTop: '24px',
    },
  },
  innerContainer: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '24px',
    background: theme.colors.blue[10],
    backgroundBlendMode: 'multiply',
    display: 'flex',
    flex: 1,
    gap: '4rem',
    padding: '120px 80px',
  },
  innerContainerHero: {
    padding: '40px 80px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '40px 24px',
      borderRadius: '20px',
    },
  },
  innerContainerEqPadding: {
    padding: '0',
  },
  innerContainerGreyBg: {
    background: theme.colors.neutral[5],
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
}))

export default RoundBackgroundBlock
